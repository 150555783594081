<template>
  <div :title="false">
    <!--page-header-wrapper-->
    <a-card>

      <menu-header @refresh="refresh" @open-form="openForm()"/>
      <p-table
        ref="table"
        size="default"
        rowKey="id"
        :scroll="{y:100}"
        extraHeight="350"
        :columns="columns"
        :sourceData="loadData"
        :pagination="false"
      >
        <span slot="action" slot-scope="text, record">
          <a-space>
            <a-button type="primary" @click="openForm(record.id)">编辑</a-button>
            <a-popconfirm
              title="确认删除吗?"
              ok-text="是"
              cancel-text="否"
              @confirm="del(record.id)"
            >
              <a-button type="primary">删除</a-button>
            </a-popconfirm>
          </a-space>
        </span>
      </p-table>
      <menu-form
        ref="form"
        :visible="formVisible"
        @success="refresh"
        @close="handleFormClose"
        :id="id"
        :menu-tree="menuTree"/>
    </a-card>
  </div>

</template>

<script>
import { menuDelete, allMenu, tree } from '@/api/employmentUser/menu'
import { STable } from '@/components'
import MenuForm from '@/views/employmentUser/form/MenuForm'
import MenuHeader from '@/views/employmentUser/tableHeader/MenuHeader'
import { mapGetters } from 'vuex'

export default {
  name: 'Menu',
  components: {
    MenuHeader,
    MenuForm,
    STable
  },
  data () {
    return {
      formVisible: false,
      id: null,
      menuTree: [],
      columns: [
        {
          title: '菜单名称',
          dataIndex: 'name'
        },
        {
          title: '菜单图标',
          dataIndex: 'icon'
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      expandedRowKeys: [],
      data: [],
      loadData: parameter => {
        return tree(this.query)
          .then(res => {
            this.menuTree = res.data
            return res
          }).catch(() => {
            return []
          })
      },
      params: {}
    }
  },
  computed: {
    rowSelection () {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    ...mapGetters('employmentUser/menu', ['query']),
    ...mapGetters('employmentSupport/dict', ['dict'])
  },
  mounted () {
    this.$store.dispatch('employmentSupport/dict/loadDict', ['menu_type', 'is_show'])
  },
  methods: {
    change (page, pageSize) {
      this.params.pageIndex = page
      this.params.pageSize = pageSize
      this.init()
    },
    openForm (id) {
      this.formVisible = true
      this.$refs.form.init(id)
    },
    refresh () {
      this.$refs['table'].refresh()
    },
    handleFormClose (value) {
      this.formVisible = value
    },
    del (id) {
      menuDelete({ id }).then(res => {
        if (res.success) {
          this.refresh()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
